import React, {useContext} from "react";
import { useTranslation } from "react-i18next";
import InputArea from "../../components/form/InputArea";
import useLoginSubmit from "../../hooks/useLoginSubmit";
import background from "../../assets/img/loginBackground.jpg"
import logo from "../../assets/img/logo/logo-light.png"
import {
  Button, Dialog, DialogTitle
} from "@mui/material";
import InputPasswordArea from "../../components/form/InputPasswordArea";
import {IoMdClose} from "react-icons/io";
import {PiSealWarning} from "react-icons/pi";
import fr from "../../assets/img/fr.svg";
import en from "../../assets/img/us.svg";
import cookies from "js-cookie";
import {SidebarContext} from "../../context/SidebarContext";
import {LANG} from "../../pref";

const Login = () => {
  const currentLanguageCode = cookies.get(LANG) || "en";
  const { t } = useTranslation();
  const { handleLanguageChange } = useContext(SidebarContext);
  const {
    onSubmit,
    loading,
    email,
    setEmail,
    password,
    setPassword,
    showPassword,
    passwordError,
    newPasswordError,
    newPasswordErrorConfirmation,
    handleClickPasswordVisibility,
    passwordVisibility,
    showCreatePassword,
    newPassword,
    setNewPassword,
    newPasswordConfirmation,
    setNewPasswordConfirmation,
    newPasswordVisibility,
    newPasswordConfirmationVisibility,
    handleClickNewPasswordVisibility,
    handleClickNewPasswordConfirmationVisibility,
    error,
    openDialog,
    openModal,
    closeModal,
    loadingReset,
    forgotPassword,
    errorReset
  } = useLoginSubmit();

  return (
      <>
        <main className="relative flex justify-center">
          <img
            src={background}
            alt="picture"
            className="absolute w-screen h-screen object-fill blur lg:blur-none"
          />
          <div
              className="absolute lg:right-36 h-screen md:w-[400px] px-3 md:px-0 flex flex-col justify-center items-center">
            <div className="w-full py-8 px-6 rounded flex flex-col items-start bg-white">
              <div className="w-full flex gap-2">
                <button
                  onClick={() => handleLanguageChange("fr")}
                  className={["flex-1 py-2 flex justify-center items-center gap-2 focus:outline-none cursor-pointer", currentLanguageCode === "fr" ? "border-2 border-primary rounded" : "border-2 border-transparent hover:border-2 hover:border-primary hover:rounded hover:bg-black/10"].join(" ")}
                >
                  <img src={fr} width={16} alt="lang"/>
                  <p>FRENCH</p>
                </button>
                <button
                  onClick={() => handleLanguageChange("en")}
                  className={["flex-1 py-2 flex justify-center items-center gap-2 focus:outline-none cursor-pointer", currentLanguageCode === "en" ? "border-2 border-primary rounded" : "border-2 border-transparent hover:border-2 hover:border-primary hover:rounded hover:bg-black/10"].join(" ")}
                >
                  <img src={en} width={16} alt="lang"/>
                  <p>English</p>
                </button>
              </div>
              <img
                src={logo}
                alt="picture"
                className="mt-4 w-full object-contain"
              />
              <form onSubmit={onSubmit} className="w-full mt-8">
                <p className="text-3xl font-bold"> {t("login.authentication")} </p>
                <p className="mb-8 mt-1 text-md font-light text-gray-500 "> {t("login.welcomeBackToTheAdminPanel")} </p>

                <InputArea
                  defaultValue=""
                  label={t("login.email")}
                  name="Email"
                  type="email"
                  placeholder={t("login.yourEmail")}
                  value={email}
                  required={true}
                  onChange={e => setEmail(e.target.value)}
                />

                {showPassword && (
                  <InputPasswordArea
                    showPassword={passwordVisibility}
                    showPasswordClick={handleClickPasswordVisibility}
                    errorMessage={passwordError}
                    label={t("login.password")}
                    placeholder={t("login.yourPassword")}
                    value={password}
                    onChange={e => setPassword(e.target.value)}/>
                )}

                {showCreatePassword && (
                  <div>
                    <InputPasswordArea
                      showPassword={newPasswordVisibility}
                      showPasswordClick={handleClickNewPasswordVisibility}
                      errorMessage={newPasswordError}
                      label={t("login.password")}
                      placeholder={t("login.yourPassword")}
                      value={newPassword}
                      onChange={e => setNewPassword(e.target.value)}/>
                    <InputPasswordArea
                      showPassword={newPasswordConfirmationVisibility}
                      showPasswordClick={handleClickNewPasswordConfirmationVisibility}
                      errorMessage={newPasswordErrorConfirmation}
                      label={t("login.confirmationPassword")}
                      placeholder={t("login.yourConfirmationPassword")}
                      value={newPasswordConfirmation}
                      onChange={e => setNewPasswordConfirmation(e.target.value)}/>
                  </div>
                )}

                {showPassword && (
                  <div className="flex justify-end mr-2">
                    <button
                      onClick={openModal}
                      type="button"
                      className="w-fit mt-8 text-primary hover:underline">
                      {t("login.forgotPassword")}
                    </button>
                  </div>
                )}

                <Button
                  fullWidth
                  sx={{mt: 4, padding: "12px 0px"}}
                  disabled={loading}
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit">
                  {loading ? t("button.loading") : t("login.login")}
                </Button>
              </form>
              {error.length !== 0 && (
                <div className="w-full mt-4 flex flex-row gap-4 items-center text-white text-center bg-red-500 px-3 py-2">
                  <div> {error}</div>
                </div>
              )}
            </div>
          </div>
        </main>

        <Dialog open={openDialog} onClose={closeModal} maxWidth="xs" fullWidth>
          <div className="flex flex-row justify-between items-center border-b pr-4">
            <DialogTitle>{t("login.forgotPassword")}</DialogTitle>
            <button
              type="button"
              className="h-8 inline-flex justify-center items-center rounded-md border border-transparent bg-red-500 lg:px-2 lg:py-2 px-2 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={closeModal}
            >
              <IoMdClose/>
            </button>
          </div>

          <div className="flex flex-col justify-center items-center py-4 px-6 text-center">
            <div className="flex flex-col justify-center items-center ">
              <PiSealWarning className="text-[50px]"/>
              <p className="text-lg font-semibold">
                {t("label.information")}
              </p>
              <p>
                {t("login.forgotPasswordWarning")}
              </p>
            </div>

            {errorReset.length !== 0 && (
              <div className="w-full mt-4 flex flex-row gap-4 items-center text-white text-center bg-red-500 px-3 py-2">
                <div> {errorReset}</div>
              </div>
            )}

            <div className="w-full flex">
              <Button
                onClick={forgotPassword}
                fullWidth
                sx={{mt: 4, padding: "12px 0px"}}
                disabled={loadingReset}
                size="large"
                variant="contained"
                color="error"
                type="button">
                {loadingReset ? t("button.loading") : t("button.reset")}
              </Button>
            </div>
          </div>
        </Dialog>
      </>
  )
}

export default Login;
