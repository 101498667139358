import PageTitle from "../../components/Typography/PageTitle";
import React from "react";
import { useTranslation } from "react-i18next";
import  Error from "../../components/global/error";
import useAdmin from "../../hooks/useAdmin";
import {Button, Dialog, DialogTitle} from "@mui/material";
import AdminTable from "../../components/admin/admin/AdminTable";
import {IoMdClose} from "react-icons/io";
import AdminUsersForm from "../../components/admin/logs/AdminUsersForm";
const Admin = () => {

    const { t } = useTranslation();

    const {
        handleChange,
        admins,
        loadingLogs,
        error,
        perPage,
        totalRows,
        handlePerRowsChange,
        handlePageChange,
        search,
        setSearch,
        changeSearchValue,
        openModal,
        loading,
        userId,
        userName,
        setUserName,
        userEmail,
        setUserEmail,
        userPhone,
        setUserPhone,
        closeModal,
        openFormDialog,
        onSubmit,
        setValue,
        lockOrUnlockUsers,
        deleteUsers,
        page,
        fetchAdminUser
    } = useAdmin();

    // console.log(response)

    return (
        <>
            <div className="flex flex-row justify-between items-center py-4 md:py-0">
                <PageTitle
                    title={t("nav.admin")}
                    location={t("nav.home")}
                    subLocation={t("nav.admin")}
                />

                <Button
                    onClick={openModal}
                    sx={{padding: "8px 12px", fontSize: '0.8rem'}}
                    size="large"
                    variant="contained"
                    color="primary"
                    className="focus:outline-none"
                    type="button">
                    {t("admin.newAccount")}
                </Button>
            </div>

            <>
                {(!loadingLogs && error.length !== 0) && (
                    <div className='bg-white px-4'>
                        <Error errorTitle="Oops" errorMessage={error} retry={()=>fetchAdminUser(perPage, page, "")}/>
                    </div>
                )}

                {error.length === 0 && (
                    <AdminTable
                        loading={loadingLogs}
                        admins={admins}
                        search={search}
                        setValue={setValue}
                        setSearch={setSearch}
                        perPage={perPage}
                        totalRows={totalRows}
                        lockOrUnlockUsers={lockOrUnlockUsers}
                        onChangeRowsPerPage={handlePerRowsChange}
                        handlePageChange={handlePageChange}
                        changeSearchValue={changeSearchValue}/>
                )}
            </>

            <Dialog open={openFormDialog} onClose={closeModal} maxWidth="sm" fullWidth>
                <div className="flex flex-row justify-between items-center border-b pr-4">
                    <DialogTitle>{userId === null ? `${t("admin.addAccount")}` : `${t("admin.account")}`}</DialogTitle>
                    <button
                        type="button"
                        className="h-8 inline-flex justify-center items-center rounded-md border border-transparent bg-primary lg:px-2 lg:py-2 px-2 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                    >
                        <IoMdClose />
                    </button>
                </div>

                <AdminUsersForm
                    onSubmit={onSubmit}
                    loading={loading}
                    userId={userId}
                    userName={userName}
                    setUserName={setUserName}
                    userEmail={userEmail}
                    userPhone={userPhone}
                    setUserPhone={setUserPhone}
                    setUserEmail={setUserEmail}
                    deleteUsers={deleteUsers}
                    closeModal={closeModal}/>
            </Dialog>
        </>
    );
};

export default Admin;
