import React, { useContext } from 'react'
import SidebarContent from './SidebarContent'
import {SidebarContext} from "../../context/SidebarContext";
import {SwipeableDrawer} from "@mui/material";

function MobileSidebar() {
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext)

    const toggleDrawer = () => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        closeSidebar()
    };

  return (
      <SwipeableDrawer
          anchor="left"
          open={isSidebarOpen}
          onClose={toggleDrawer()}
          onOpen={toggleDrawer()}
      >
        <SidebarContent />
      </SwipeableDrawer>
  )
}

export default MobileSidebar
