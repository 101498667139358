import React, {useContext, useState} from "react";
import {NavLink, Route, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import { IoLogOutOutline } from "react-icons/io5";
import logo from "../../assets/img/logo/logo.png";

import sidebarAdmin from "../../routes/sidebarAdmin";
import { AdminContext } from "../../context/AdminContext";
import SidebarSubMenu from "./SidebarSubMenu";
// import SidebarSubMenu from "SidebarSubMenu";
import { useTranslation } from "react-i18next";
import {Button} from "@mui/material";
import {ADMIN, USER} from "../../pref";
import routesAdmin from "../../routes/routesAdmin";
import routesDealer from "../../routes/routesDealer";
import sidebarDealer from "../../routes/sidebarDealer";
import AdminServices from "../../services/AdminServices";
import {notifyError, notifySuccess} from "../../hooks/toast";
import parseErrorMessage from "../../utils/ErrorMessage";
const SidebarContent = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AdminContext);
  const navigate = useNavigate();

  const { state } = useContext(AdminContext);
  const { adminInfo } = state;

  const [loading,setLoading] = useState(false);

  const handleLogOut = () => {
      setLoading(true);

      AdminServices.logout()
          .then((res)=>{
              dispatch({ type: "USER_LOGOUT" });
              Cookies.remove(USER);
              navigate("/");
          }).catch((err) => {
              notifyError(parseErrorMessage(err))
              // console.log("Something went wrong! Please try again in a moment!")
          }).finally(()=>setLoading(false))
  };

  function getAuthDealer(){
      if(adminInfo?.account?.principal){
          return sidebarDealer
      }

      console.log(sidebarDealer.filter(item => item.name !== "nav.users"))

      return sidebarDealer.filter(item => item.name !== "nav.users")
  }

    function getAuthAdmin(){
        if(adminInfo?.master){
            return sidebarAdmin
        }

        console.log(sidebarAdmin.filter(item => item.name !== "nav.admin"))

        return sidebarAdmin.filter(item => item.name !== "nav.admin")
    }

  return (
    <div className="w-full min-h-screen max-w-[250px] lg:max-w-[300px] flex flex-col py-4 text-gray-500">
      <div className="flex-none w-full flex justify-center items-center py-8">
          <img src={logo} alt="dashtar" className="w-36 " />
      </div>
      <div className="flex-1 flex flex-col h-full mt-8 mx-2">
        {(adminInfo?.role === ADMIN ? getAuthAdmin() : getAuthDealer()).map((route) =>
          route.routes ? (
            <SidebarSubMenu route={route} key={route.name} />
          ) : (
            <div className="relative" key={route.name}>
              {route?.outside ? (
                <a
                  href={process.env.REACT_APP_STORE_DOMAIN}
                  target="_blank"
                  className="px-4 py-4 inline-flex items-center cursor-pointer w-full text-sm font-semibold transition-colors duration-150 hover:font-extrabold hover:text-primary"
                  rel="noreferrer"
                >
                  <Route path={route.path} exact={route.exact}>
                    <span
                      className="absolute inset-y-0 left-0 w-1 bg-green-500 rounded-tr-lg rounded-br-lg"
                      aria-hidden="true"
                    ></span>
                  </Route>
                  <route.icon className="w-5 h-5 flex-none" aria-hidden="true" />
                  <span className="ml-4">{t(`${route.name}`)}</span>
                  {/* <span className="ml-4">{route.name}</span> */}

                </a>
              ) : (
                <NavLink
                  exact
                  to={route.path}
                  target={`${route?.outside ? "_blank" : "_self"}`}
                  className={(navData) =>
                      !navData.isActive
                          ? "px-4 py-4 rounded inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:font-extrabold hover:text-primary"
                          : "px-4 py-4 rounded inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 text-white bg-primary"
                  }
                >
                  <route.icon className="w-5 h-5 flex-none" aria-hidden="true" />
                  <span className="ml-4">{t(`${route.name}`)}</span>
                </NavLink>
              )}
            </div>
          )
        )}
      </div>
      <div className="flex-none mx-2 pb-3 lg:pb-0">
          <Button disabled={loading} onClick={handleLogOut} size="large" variant="contained" className="mx-2 w-full">
              {loading ? (
                  <div className="text-gray-500">Déconnexion ...</div>
              ) : (
                  <div className="flex gap-3 items-center">
                    <IoLogOutOutline className=" text-lg"/>
                    <span className="text-sm">{t("LogOut")}</span>
                </div>
              )}
          </Button>
      </div>
    </div>
  );
};

export default SidebarContent;
