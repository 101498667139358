import {lazy, useContext} from "react";
// use lazy for better code splitting
const DashboardDealer = lazy(() => import("../pages/dealer/Dashboard"));
const Page404 = lazy(() => import("../pages/404"));
const SettingDealer = lazy(() => import("../pages/dealer/Setting"));
const Warranty = lazy(() => import("../pages/dealer/Warranty"));
const Claims = lazy(() => import("../pages/dealer/Claims"));
const DealerUsers = lazy(() => import("../pages/dealer/Users"));
const Report = lazy(() => import("../pages/dealer/Report"));
/*
//  * ⚠ These are internal routes!
//  * They will be rendered inside the app, using the default `containers/Layout`.
//  * If you want to add a route to, let's say, a landing page, you should add
//  * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
//  * are routed.
//  *
//  * If you're looking for the links rendered in the SidebarContent, go to
//  * `routes/sidebarAdmin.js`
 */
const routesDealer = [
  {
    path: "/",
    component: DashboardDealer,
  },
  {
    path: "/warranty",
    component: Warranty,
  },
  {
    path: "/claims",
    component: Claims,
  },
  {
    path: "/dealerUsers",
    component: DealerUsers,
  },
  {
    path: "/reports",
    component: Report,
  },
  {
    path: "/setting",
    component: SettingDealer,
  },
  {
    path: "/404",
    component: Page404,
  },
];

export default routesDealer;
