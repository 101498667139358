import axios from 'axios';
import Cookies from 'js-cookie';
import {LANG, USER} from "../pref";
import cookies from "js-cookie";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 60000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  timeoutErrorMessage: "Le serveur met trop de temps à répondre, réessayez plus tard.",
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let adminInfo;
  if (Cookies.get(USER)) {
    adminInfo = JSON.parse(Cookies.get(USER));
    // console.log(adminInfo.token)
  }

  const currentLanguageCode = cookies.get(LANG) || "en";

  return {
    ...config,
    headers: {
      authorization: adminInfo ? `Bearer ${adminInfo.token}` : null,
      // company: company ? company : null,
      "Access-Control-Allow-Origin": "*",
      "locale": currentLanguageCode
    },
  };
});

instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (parseInt(error.response.status) === 401 || parseInt(error.response.status) === 403) {
        Cookies.remove(USER);
        window.location.replace(`/login`);
      }
      return Promise.reject(error);
    }
);

const responseBody = (response) => response.data;

const requests = {
  get: (url, body) => instance.get(url, body).then(responseBody),

  post: (url, body, headers) => instance.post(url, body, headers).then(responseBody),

  put: (url, body, headers) => instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url, body) => instance.delete(url, { data: body}).then(responseBody),
};

export default requests;
