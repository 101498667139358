import {
  FiGrid,
  FiTrello,
  FiSettings
} from "react-icons/fi";

import {
  TbTractor
} from "react-icons/tb";

import {
  AiOutlineUser
} from "react-icons/ai";

import {
  MdOutlineDocumentScanner,
  MdOutlinePsychologyAlt
} from "react-icons/md";

import {
  TiDocumentDelete
} from "react-icons/ti";

import { PiUsersThreeDuotone } from "react-icons/pi";

/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/routesAdmin.js`
 */
const sidebarAdmin = [
  {
    path: "/", // the url
    icon: FiGrid, // icon
    name: "nav.home", // name that appear in Sidebar
  },
  {
    path: "/tractors", // the url
    icon: TbTractor, // icon
    name: "nav.tractors", // name that appear in Sidebar
  },
  {
    path: "/dealers", // the url
    icon: AiOutlineUser, // icon
    name: "nav.dealers", // name that appear in Sidebar
  },
  {
    path: "/status", // the url
    icon: FiTrello, // icon
    name: "nav.status", // name that appear in Sidebar
  },
  {
    path: "/claims", // the url
    icon: MdOutlineDocumentScanner, // icon
    name: "nav.claims", // name that appear in Sidebar
  },
  {
    path: "/oldClaims", // the url
    icon: TiDocumentDelete, // icon
    name: "nav.claimsOld", // name that appear in Sidebar
  },
  {
    path: "/admin", // the url
    icon: PiUsersThreeDuotone, // icon
    name: "nav.admin", // name that appear in Sidebar
  },
  {
    path: "/logs", // the url
    icon: MdOutlinePsychologyAlt, // icon
    name: "nav.logs", // name that appear in Sidebar
  },
  {
    path: "/setting", // the url
    icon: FiSettings, // icon
    name: "nav.setting", // name that appear in Sidebar
  },
];

export default sidebarAdmin;
