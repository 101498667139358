import Cookies from 'js-cookie';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { AdminContext } from '../context/AdminContext';
import AdminServices from '../services/AdminServices';
import { notifyError, notifySuccess } from './toast';
import parseErrorMessage from "../utils/ErrorMessage";
import {AuthenticatedAxios} from "../services/httpService";
import axios from "axios";
import {USER} from "../pref";

const useLoginSubmit = () => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AdminContext);
  const navigate = useNavigate();
  // const location = useLocation();

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [showCreatePassword, setCreateShowPassword] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [newPasswordVisibility, setNewPasswordVisibility] = useState(false)
  const [newPasswordConfirmationVisibility, setNewPasswordConfirmationVisibility] = useState(false)
  const [passwordError, setPasswordError] = useState("")
  const [newPasswordError, setNewPasswordError] = useState("")
  const [newPasswordErrorConfirmation, setNewPasswordErrorConfirmation] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("")

  const handleClickPasswordVisibility = () => setPasswordVisibility((show) => !show);
  const handleClickNewPasswordVisibility = () => setNewPasswordVisibility((show) => !show);
  const handleClickNewPasswordConfirmationVisibility = () => setNewPasswordConfirmationVisibility((show) => !show);
  const handleShowCreatePassword = () => setCreateShowPassword((show) => !show);
  const handleShowPassword = () => setShowPassword((show) => !show);

  useEffect(()=>{
    setPasswordError("")
  }, [password])

  const onSubmit = (event) => {
    // console.log(event)
    event.preventDefault();

    // validate form
    if(showPassword && password.length < 7){
      setPasswordError("too short")
      return;
    }

    if(showCreatePassword && newPassword.length < 7){
      setNewPasswordError("too short")
      return;
    }

    if(showCreatePassword && newPasswordConfirmation.length < 7){
      setNewPasswordErrorConfirmation("too short")
      return;
    }

    if(showCreatePassword && (newPasswordConfirmation !== newPassword)){
      setNewPasswordErrorConfirmation("password does not match")
      return;
    }

    if (!showPassword && !showCreatePassword) {
      setLoading(true);
      AdminServices.CheckEmail({email: email}).then(response => {
        // console.log(response)
        response.data.exists ? setShowPassword(true) : setCreateShowPassword(true)
        setLoading(false)
      }).catch(e => {
        console.log(e)
        setError(parseErrorMessage(e))
        showErrorTimer()
      }).finally(()=>setLoading(false))

      return;
    }

    if (showCreatePassword) {
      setLoading(true);

      const body = {
        "email": email,
        "password": newPasswordConfirmation
      };

      AdminServices.choosePassword(body)
        .then(async (res) => {
          const token = res.data.token
          const account = res.data.account
          await getUser(token, account)
        })
        .catch((err) => {
          // console.log(err)
          setError(parseErrorMessage(err))
          showErrorTimer()
        }).finally(()=>setLoading(false))

    }else{
      setLoading(true);

      const body = {
        "email": email,
        "password": password
      };

      AdminServices.loginAdmin(body)
        .then(async (res) => {
          if (res) {
            const token = res.data.token
            const account = res.data.account
            await getUser(token, account)
          }
        }).catch((err) => {
          console.log(err)
          setError(parseErrorMessage(err))
          showErrorTimer()
        }).finally(()=>setLoading(false))
    }
  };

  function showErrorTimer(){
    setTimeout(() => {
      setError("")
    }, 5000);
  }

  async function getUser(token, account) {
    setLoading(true);
    const response = await axios.create({
      baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
      timeout: 10_000,
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).get("/user").then(async (res) => {
      notifySuccess('Login Success!');
      const user = {
        ...res?.data?.data?.user,
        token: token,
        account: account
      }

      dispatch({type: 'USER_LOGIN', payload: user});
      Cookies.set(USER, JSON.stringify(user), {
        expires: 0.5,
      });
      navigate('/', {replace: true});
    }).catch((err) => {
      console.log(err)
      setError(parseErrorMessage(err))
      showErrorTimer()
    }).finally(()=>setLoading(false))
  }

  const [loadingReset, setLoadingReset] = useState(false);
  const [errorReset, setErrorReset] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const openModal = () => setOpenDialog(true)
  const closeModal = () => setOpenDialog(false)

  function forgotPassword() {
    setLoadingReset(true)

    const body = {email: email}

    AdminServices.forgetPassword(body)
      .then(async (res) => {
        if (res?.success) {
          closeModal();
          notifySuccess(res?.message)
        }else{
          setErrorReset(res?.message)
          showErrorResetTimer()
        }
      }).catch((err) => {
        console.log(err)
        setErrorReset(parseErrorMessage(err))
        showErrorResetTimer()
      }).finally(()=>setLoadingReset(false))
  }

  function showErrorResetTimer(){
    setTimeout(() => {
      setErrorReset("")
    }, 5000);
  }
  
  return {
    onSubmit,
    loading,
    email,
    setEmail,
    password,
    setPassword,
    showPassword,
    passwordError,
    newPasswordError,
    newPasswordErrorConfirmation,
    handleClickPasswordVisibility,
    passwordVisibility,
    showCreatePassword,
    handleShowCreatePassword,
    newPassword,
    setNewPassword,
    newPasswordConfirmation,
    setNewPasswordConfirmation,
    handleShowPassword,
    newPasswordVisibility,
    newPasswordConfirmationVisibility,
    handleClickNewPasswordVisibility,
    handleClickNewPasswordConfirmationVisibility,
    error,
    openDialog,
    openModal,
    closeModal,
    loadingReset,
    forgotPassword,
    errorReset
  };
};

export default useLoginSubmit;
