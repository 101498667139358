import React from "react"
import errorImage from "../../assets/img/404.svg";

interface errorProps {
    errorTitle: string | null,
    errorMessage: string,
    retry: any;
}

const Error = ({errorTitle, errorMessage, retry}: errorProps) => {

    function retryRequest() {
        retry()
    }

    return (
        <div>
            <div className="w-full h-full flex flex-col justify-center items-center py-8 ">
                <img
                    src={errorImage}
                    className="w-96 lg:w-[600px]"
                    alt="error"
                />
                <div className="text-2xl font-semibold text-center">{errorTitle}</div>
                <div className="mt-4 text-xl text-center">{errorMessage}</div>
                <div className="mt-4">
                    <button type="button" onClick={retry}
                            className="inline-block px-8 py-3 bg-primary hover:bg-primary text-white font-semibold text-xs leading-tight uppercase rounded-md shadow-md hover:shadow-lg focus:bg-primary focus:shadow-lg focus:outline-none focus:ring-0 active:bg-active active:shadow-lg transition duration-150 ease-in-out">
                        Réessayer
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Error
