import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";

const PrivateRoute = () => {
  const { state } = useContext(AdminContext);
  const { adminInfo } = state;

  return (
      adminInfo?.token ? <Outlet/> : <Navigate to="/login"/>
  );
};

export default PrivateRoute;
