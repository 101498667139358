import React from "react";
import {FormHelperText, InputAdornment, TextField} from "@mui/material";

const InputArea = ({
 defaultValue,
 required,
 name,
 label,
 type,
 prefix,
 placeholder,
 value,
 variant,
 onChange,
 readOnly,
 border,
 errorMessage,
 minLength,
 disabled,
 onBlur
}) => {
  return (
      <div className="w-full relative ">
        {prefix && (
            <div className='absolute top-[18px] px-2 text-[14.5px] flex justify-center items-center'>
                {prefix}
            </div>
        )}
          <TextField
              disabled={disabled ?? false}
              required={required !== undefined}
              fullWidth
              label={label ?? ""}
              variant={variant ?? "outlined"}
              // variant="outlined"
              type={type}
              size="medium"
              startAdornment={"USD"}
              error={errorMessage !== undefined ? errorMessage.length > 0 : false}
              placeholder={placeholder}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              sx={{
                  border: border ? 'none' : '',
                  "& fieldset": {
                    border: border ? 'none' : ''
                  }
              }}
              inputProps={{
                  minLength: minLength ?? 0,
                  readOnly: readOnly ?? false,
                  // inputMode: type === "number" ? 'numeric' : 'text',
                  // pattern: type === "number" ? '[0-9]*' : ''
              }}
          />
          <FormHelperText error={errorMessage !== undefined ? errorMessage.length > 0 : false}>
              {errorMessage ? errorMessage : ""}
          </FormHelperText>
      </div>
  );
};

export default InputArea;
