import React from "react";
import {MdWarning} from "react-icons/md";
import {useTranslation} from "react-i18next";
import {GrFormClose} from "react-icons/gr";
import {Button} from "@mui/material";

const ReportAccept = ({
  loadingAccept,
  onClose,
  refresh,
  onSubmitAccept,
  title,
  desc,
    fileAccept,
    setFileAccept
}) => {
    const { t } = useTranslation();

    console.log("fileAccept ", fileAccept)

    return (
        <div
            className="shadow-2xl bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mx-4 md:relative">
            <div className="md:flex items-center">
                <div
                    className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                    <MdWarning className="text-3xl text-primary"/>
                </div>
                <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                    <p className="font-bold">{title}</p>
                    <p className="text-sm text-gray-700 mt-1">
                        {desc}
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-6 my-4">
                <div
                    className="h-14 w-full flex flex-row gap-2 items-center border-[1.5px] py-1 pl-3 pr-0.5 rounded ">
                    <div className="flex-1 flex text-gray-500">
                        <div className="flex-1">
                            {fileAccept === null ? t("report.chooseCreditWarrantFile") : (fileAccept?.name?.length > 25 ? fileAccept?.name?.substring(0, 24) : `${fileAccept?.name}...`)}
                        </div>
                        {fileAccept !== null && (
                            <button
                                className="flex-none"
                                color="primary"
                                onClick={() => {
                                    setFileAccept(null)
                                }}>
                                <GrFormClose/>
                            </button>
                        )}
                    </div>
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className='flex-none h-12'
                    >
                        {fileAccept === null ? t("tractor.choose") : t("tractor.change")}
                        <input
                            type="file"
                            accept=".pdf, .xls, .xlsx"
                            hidden
                            onChange={async (e) => setFileAccept(e.target.files[0])}
                        />
                    </Button>
                </div>
            </div>
            <div className="text-center md:text-right md:flex md:justify-end">
                <button
                    onClick={async () => {
                        await onSubmitAccept()
                        onClose()
                    }}
                    disabled={loadingAccept}
                    className="block w-full md:inline-block focus:outline-none hover:shadow md:w-auto px-4 py-3 md:py-2 bg-primary/30 text-primary rounded-lg font-semibold text-sm md:ml-2 md:order-2">
                    {loadingAccept ? `${t("button.loading")} ...` : `${t("button.accept")}`}
                </button>
                <button
                    onClick={onClose}
                    className="block w-full md:inline-block focus:outline-none hover:shadow md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1">
                    {t("button.cancel")}
                </button>
            </div>
        </div>
    )
}

export default ReportAccept
