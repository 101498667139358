import React, {useContext, useEffect, useState} from "react";
import {SidebarContext} from "../context/SidebarContext";
import {notifyError, notifySuccess} from "./toast";
import {useDebouncedCallback} from "use-debounce";
import AdminServices from "../services/AdminServices";
import DealerServices from "../services/DealerServices";
import parseErrorMessage from "../utils/ErrorMessage";

const useAdmin = () =>{
    const {
        setIsUpdate
    } = useContext(SidebarContext);

    const [loading, setLoading] = useState(false);
    const [loadingLogs, setLoadingLogs] = useState(true);
    const [admins, setAdmins] = useState([]);
    const [error, setError] = useState("");
    const [search, setSearch] = useState("");

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");

    const [openFormDialog, setOpenFormDialog] = useState(false);
    const openModal = () => setOpenFormDialog(true)
    const closeModal = () =>{
        clear()
        setOpenFormDialog(false)
    }

    const clear = () => {
        setUserId(null);
        setUserName("");
        setUserEmail("");
        setUserPhone("");
    }

    const setValue = (value) => {
        setUserId(value?.id);
        setUserName(value?.name)
        setUserEmail(value?.email)
        setUserPhone(value?.phone)

        openModal()
    }

    useEffect(() => {
        fetchAdminUser(perPage, page, "")
    }, []);

    const handleChange = (event) => {
        // console.log(coutries)
        console.log(event.target.value)
    };

    const fetchAdminUser = (perPage, page, search) => {
        setError("")
        setLoadingLogs(true)

        AdminServices
            .getAllAdmin(perPage, page, search)
            .then((res)=>{
                console.log(res?.data?.admins?.data)
                setAdmins(res?.data?.admins?.data)
                setTotalRows(res?.data?.total)
            }).catch((err) => {
                setError(parseErrorMessage(err))
                // console.log("Something went wrong! Please try again in a moment!")
            }).finally(()=>setLoadingLogs(false))
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        const body = {
            "name": userName,
            "email": userEmail,
            "phone": userPhone
        }

        setLoading(true)

        AdminServices
            .addAdminUsers(body)
            .then((res)=>{
                notifySuccess(res.message)
                closeModal()
                fetchAdminUser(perPage, page, "")
            }).catch((err) => {
                notifyError(parseErrorMessage(err))
                // console.log("Something went wrong! Please try again in a moment!")
            }).finally(()=>setLoading(false))
    };

    const lockOrUnlockUsers = async (id, active) => {
        const body = {
            "admin": id,
            "active": active,
        }

        setLoading(true)

        DealerServices
            .lockOrUnlockUsers(body)
            .then((res)=>{
                notifySuccess(res.message)
                closeModal()
                fetchAdminUser(perPage, page, "")
            }).catch((err) => {
                notifyError(parseErrorMessage(err))
                // console.log("Something went wrong! Please try again in a moment!")
            }).finally(()=>setLoading(false))
    };

    const deleteUsers = async (id) => {
        const body = {"admin": id}
        setLoading(true)

        DealerServices
            .deleteUsers(body)
            .then((res)=>{
                notifySuccess(res.message)
                closeModal()
                fetchAdminUser(perPage, page, "")
            }).catch((err) => {
                notifyError(parseErrorMessage(err))
                // console.log("Something went wrong! Please try again in a moment!")
            }).finally(()=>setLoading(false))
    };

    const handlePerRowsChange = (newPerPage, page) => fetchAdminUser(newPerPage, 1, search)
    const handlePageChange = (page, totalRows) => fetchAdminUser(perPage, page, search)

    const changeSearchValue = useDebouncedCallback(
        (searchValue) => fetchAdminUser(perPage, 1, searchValue),
        // delay in ms
        700
    );

    return {
        handleChange,
        admins,
        loadingLogs,
        error,
        perPage,
        totalRows,
        handlePerRowsChange,
        handlePageChange,
        search,
        setSearch,
        changeSearchValue,
        openModal,
        userId,
        userName,
        setUserName,
        userEmail,
        setUserEmail,
        userPhone,
        setUserPhone,
        loading,
        closeModal,
        openFormDialog,
        onSubmit,
        setValue,
        lockOrUnlockUsers,
        deleteUsers,
        page,
        fetchAdminUser
    }
}

export default useAdmin;