import React from "react";
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField
} from "@mui/material";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";

const InputPasswordArea = ({
 defaultValue,
 required,
 name,
 label,
 placeholder,
 showPassword,
 showPasswordClick,
 value,
 onChange,
 errorMessage
}) => {
  return (
      <FormControl
          sx={{mt: 3}}
          variant="outlined"
          size="medium"
          fullWidth>
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
              required
              error={errorMessage.length > 0}
              placeholder={placeholder}
              type={showPassword ? 'text' : 'password'}
              value={value}
              onChange={onChange}
              endAdornment={
                  <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={showPasswordClick}
                          className="focus:outline-none"
                          edge="end">
                          {showPassword ? <AiOutlineEye className="text-xl text-[#A5A5A5] text-gr"/> : <AiOutlineEyeInvisible className="text-xl text-[#A5A5A5] text-gr"/>}
                      </IconButton>
                  </InputAdornment>
              }
              label="Password"
          />
          <FormHelperText error={errorMessage.length > 0}>{errorMessage ? errorMessage : ""}</FormHelperText>
      </FormControl>
  );
};

export default InputPasswordArea;
