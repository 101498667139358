import {
  FiGrid,
  FiSettings,
  FiUsers
} from "react-icons/fi";

/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/routesAdmin.js`
 */
const sidebarDealer = [
  {
    path: "/", // the url
    icon: FiGrid, // icon
    name: "nav.home", // name that appear in Sidebar
  },
  {
    path: "/dealerUsers", // the url
    icon: FiUsers, // icon
    name: "nav.users", // name that appear in Sidebar
  },
  {
    path: "/setting", // the url
    icon: FiSettings, // icon
    name: "nav.setting", // name that appear in Sidebar
  }
];

export default sidebarDealer;
