import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./assets/css/custom.css";
import "./assets/css/tailwind.css";
import { SidebarProvider } from "./context/SidebarContext";
import ThemeSuspense from "./components/theme/ThemeSuspense";
import {AdminProvider} from "./context/AdminContext";
import { Provider } from "react-redux";
import store from "./redux/Store";
import "./i18n";
import "./theme";
import { ThemeProvider} from "@mui/material/styles";
import theme from "./theme";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <ThemeProvider theme={theme}>
        <AdminProvider>
            <SidebarProvider>
                <Provider store={store}>
                    <Suspense fallback={<ThemeSuspense />}>
                        <App />
                    </Suspense>
                </Provider>
            </SidebarProvider>
        </AdminProvider>
    </ThemeProvider>
);
