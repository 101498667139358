import DataTable from 'react-data-table-component';
import React, {useEffect, useState} from "react"
import Box from "@mui/material/Box";
import {Button, LinearProgress} from "@mui/material";
import {
    AiOutlineClose
} from "react-icons/ai";
import {confirmAlert} from "react-confirm-alert";
import ReportAccept from "../../report/report_accept";
import {useTranslation} from "react-i18next";

const AdminTable = ({
    loading,
    admins,
    perPage,
    totalRows,
    onChangeRowsPerPage,
    handlePageChange,
    search,
    setSearch,
    changeSearchValue,
    setValue,
    lockOrUnlockUsers
}) => {
    const { t } = useTranslation();

    const handleRowClicked = (row) => setValue(row);

    const columns = [
        {
            name: `${t("admin.user")}`,
            selector: row => row?.name,
            sortable: true,
        },
        {
            name: `${t("admin.email")}`,
            selector: row => row?.email,
        },
        {
            name: `${t("admin.phoneNumber")}`,
            selector: row => row?.phone,
        },
        {
            name: `${t("admin.country")}`,
            selector: row => row?.country,
            sortable: true,
        },
        {
            name: `${t("admin.status")}`,
            selector: row => row?.active ? "active" : "locked",
            sortable: true,
        },
        {
            name: `${t("admin.country")}`,
            cell: row => (
                <Button
                    sx={{padding: "8px 12px", fontSize: '0.8rem'}}
                    size="small"
                    variant="contained"
                    color={row?.active ? "primary" : "secondary"}
                    type="button"
                    className="h-fit focus:outline-none uppercase"
                    onClick={()=>{
                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <ReportAccept
                                        loadingAccept={loading}
                                        onClose={onClose}
                                        title={`${t("admin.areYouSure")}`}
                                        desc={`${t("admin.YouAreGoingTo")} ${row?.active ? `${t("admin.lock")}` : `${t("admin.unlock")}`} ${t("admin.thisUsers")}.`}
                                        onSubmitAccept={()=>lockOrUnlockUsers(row?.id, !row?.active)}/>
                                );
                            }
                        });
                    }}>
                    {row?.active ? `${t("admin.lock")}` : `${t("admin.unlock")}`}
                </Button>
            ),
            sortable: true,
        }
    ];

    return (
        <DataTable
            columns={columns}
            // data={admins.filter(it=> !(it.master === true))}
            data={admins.filter(it=> !(it.master === true))}
            onRowClicked={handleRowClicked}
            // conditionalRowStyles={conditionalRowStyles}
            subHeader
            subHeaderComponent={
                <div
                    className="border h-[64px] w-full bg-white rounded-md overflow-hidden flex flex-row pl-5 my-2 md:my-4">
                    <div className="h-full flex-1 flex justify-center relative">
                        <input id="text" type="text" name="text" placeholder={`${t("admin.searchHere")} ...`}
                               className="w-full pr-3 focus:outline-none"
                               value={search}
                               onChange={(e) => {
                                   const searchValue = e.target.value;
                                   setSearch(searchValue);
                                   changeSearchValue(searchValue);
                               }}
                               minLength={3}
                               required/>
                        {search && (
                            <div
                                className="absolute z-50 top-0 h-16 right-0 w-12 flex justify-center items-center focus:outline-none">
                                <button
                                    className='w-1/3'
                                    type='button'
                                    onClick={() => {
                                        setSearch("")
                                        changeSearchValue("")
                                    }}>

                                    <AiOutlineClose size="16px"/>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            }
            progressComponent={
                <div className="w-full my-4 px-8">
                    <Box sx={{width: '100%'}}>
                        <LinearProgress
                            style={{}}
                            color="success"
                            sx={{
                                backgroundColor: '#81c784',
                                animationDuration: '850ms',
                            }}/>
                    </Box>
                    <div className="mt-4 text-primary text-lg font-semibold">{t("admin.loading")} ...</div>
                </div>
            }
            noDataComponent={
                <div className="m-4 text-lg font-semibold text-center">
                    {t("tractor.thereIsNoUserSaved")}
                </div>
            }
            responsive={true}
            // dense={true}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            progressPending={loading}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 10, 15]}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={handlePageChange}
            highlightOnHover
            pointerOnHover
            persistTableHead={true}
        />
    )
}

export default AdminTable;
