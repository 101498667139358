import { lazy } from "react";
import Admin from "../pages/admin/admin";

// use lazy for better code splitting
const Dashboard = lazy(() => import("../pages/admin/Dashboard"));
// const Setting = lazy(() => import("../pages/Setting"));
const Page404 = lazy(() => import("../pages/404"));
const ComingSoon = lazy(() => import("../pages/admin/ComingSoon"));
const Tractors = lazy(() => import("../pages/admin/Tractors"));
const Dealers = lazy(() => import("../pages/admin/Dealers"));
const Status = lazy(() => import("../pages/admin/Status"));
const Claims = lazy(() => import("../pages/admin/Claims"));
const ClaimsOld = lazy(() => import("../pages/admin/ClaimsOld"));
const Logs = lazy(() => import("../pages/admin/logs"));
const Report = lazy(() => import("../pages/admin/Report"));
const ReportWarranty = lazy(() => import("../pages/admin/ReportWarranty"));
const Setting = lazy(() => import("../pages/admin/Setting"));
/*
//  * ⚠ These are internal routes!
//  * They will be rendered inside the app, using the default `containers/Layout`.
//  * If you want to add a route to, let's say, a landing page, you should add
//  * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
//  * are routed.
//  *
//  * If you're looking for the links rendered in the SidebarContent, go to
//  * `routes/sidebarAdmin.js`
 */

const routesAdmin = [
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/coming-soon",
    component: ComingSoon,
  },
  {
    path: "/tractors",
    component: Tractors,
  },
  {
    path: "/dealers",
    component: Dealers,
  },
  {
    path: "/status",
    component: Status,
  },
  {
    path: "/claims",
    component: Claims,
  },
  {
    path: "/reports",
    component: Report,
  },
  {
    path: "/reportWarranty",
    component: ReportWarranty,
  },
  {
    path: "/oldClaims",
    component: ClaimsOld,
  },
  {
    path: "/admin",
    component: Admin,
  },
  {
    path: "/logs",
    component: Logs,
  },
  {
    path: "/setting",
    component: Setting,
  },
  {
    path: "/404",
    component: Page404,
  },
];

export default routesAdmin;
