import React, { useContext, Suspense, useEffect, lazy } from "react";
import {Switch, Route, Redirect, useLocation, Outlet} from "react-router-dom";

import Main from "./Main";
import routesAdmin from "../routes/routesAdmin";
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import { SidebarContext } from "../context/SidebarContext";
import ThemeSuspense from "../components/theme/ThemeSuspense";
import {AdminContext} from "../context/AdminContext";

const Page404 = lazy(() => import("../pages/404"));

const Layout = () => {
  const { isSidebarOpen, closeSidebar, navBar } = useContext(SidebarContext);
  let location = useLocation();

  const { state } = useContext(AdminContext);
  const { adminInfo } = state;

  console.log(adminInfo?.role)

  useEffect(() => {
    closeSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div
      className={`flex h-screen bg-gray-50 ${
        isSidebarOpen && "overflow-hidden"
      }`}
    >
      {navBar && <Sidebar />}

      <div className="flex flex-col flex-1 w-full overflow-hidden">
        <Header />
        <Main>
          {/*<div className="text-4xl text-red-600">dsqdjsql</div>*/}
          <Suspense fallback={<ThemeSuspense />}>
            <Outlet/>
          </Suspense>
        </Main>
      </div>
    </div>
  );
};

export default Layout;
