import React, {useContext} from 'react';
import {
    BrowserRouter, Navigate,
    Route,
    Routes,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import './App.css';
import {ToastContainer} from "react-toastify";
import Login from "./pages/login/Login";
import Layout from "./layout/Layout";
import PrivateRoute from './components/login/PrivateRoute';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-confirm-alert/src/react-confirm-alert.css';
import {AdminContext} from "./context/AdminContext";
import {ADMIN} from "./pref";
import routesAdmin from "./routes/routesAdmin";
import routesDealer from "./routes/routesDealer";
import NotFound from "./pages/404";

function App() {
    const { state } = useContext(AdminContext);
    const { adminInfo } = state;

    function getAuthDealer(){
        if(adminInfo?.account?.principal){
            return routesDealer
        }

        return routesDealer.filter(item => item.path !== "/dealerUsers")
    }

    function getAuthAdmin(){
        if(adminInfo?.master){
            return routesAdmin
        }

        return routesAdmin.filter(item => item.path !== "/admin")
    }

    return (
        <>
            <ToastContainer />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <BrowserRouter>
                    <AccessibleNavigationAnnouncer />
                    <Routes>
                        <Route path="/login" Component={Login} />

                        <Route path="/" element={<PrivateRoute />}>
                            <Route path="/" element={<Layout />}>
                                {(adminInfo?.role === ADMIN ? getAuthAdmin() : getAuthDealer()).map((route, i) => {
                                    return route.component ? (
                                        <Route
                                            key={i}
                                            path={`${route.path}`}
                                            element={<route.component />}
                                        />
                                    ) : null;
                                })}
                            </Route>
                        </Route>

                        <Route path="*" Component={NotFound} />
                    </Routes>
                </BrowserRouter>
            </LocalizationProvider>
        </>
    );
}

export default App;
