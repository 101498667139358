import React from "react"
import {Button, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {FaEnvelope, FaUser, FaPhone} from "react-icons/fa";
import InputArea from "../../form/InputArea";
import {confirmAlert} from "react-confirm-alert";
import ReportAccept from "../../report/report_accept";
import {useTranslation} from "react-i18next";

const AdminUsersForm = ({
    onSubmit,
    userId,
    userName,
    setUserName,
    userEmail,
    setUserEmail,
    userPhone,
    setUserPhone,
    loading,
    deleteUsers,
    closeModal
}) => {
    const { t } = useTranslation();

    return (
        <form onSubmit={onSubmit} className="flex flex-col gap-5">
            <DialogContent className='flex flex-col gap-8'>
                <div className="flex flex-col gap-5">
                    <div className="flex items-center gap-2">
                        <FaUser className="flex-none text-2xl text-primary"/>
                        <InputArea
                            defaultValue=""
                            label={`${t("admin.name")}`}
                            placeholder={`${t("admin.name")}`}
                            name="Name"
                            type="text"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    <FaEnvelope className="flex-none text-2xl text-primary"/>
                    <InputArea
                        defaultValue=""
                        label={`${t("admin.email")}`}
                        placeholder={`${t("admin.email")}`}
                        name="Email"
                        type="text"
                        required={false}
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <FaPhone className="flex-none text-2xl text-primary"/>
                    <InputArea
                        defaultValue=""
                        label={`${t("admin.phoneNumber")}`}
                        placeholder={`${t("admin.phoneNumber")}`}
                        name="PhoneNumber"
                        type="number"
                        required={false}
                        value={userPhone}
                        onChange={(e) => setUserPhone(e.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {/*<Button onClick={()=>{}}>Cancel</Button>*/}
                <div className="w-full flex flex-row gap-2 px-4 pb-3 border-t">
                    {userId === null ? (
                        <Button
                            fullWidth
                            sx={{mt: 4, padding: "8px 0px"}}
                            disabled={loading}
                            size="large"
                            variant="contained"
                            color="primary"
                            className="focus:outline-none"
                            type="submit">
                            {loading ? `${t("admin.loading")} ...` : `${t("admin.save")}`}
                        </Button>
                    ) : (
                        <Button
                            fullWidth
                            sx={{mt: 4, padding: "8px 0px"}}
                            disabled={loading}
                            size="large"
                            variant="contained"
                            color="secondary"
                            className="focus:outline-none"
                            type="button"
                            onClick={()=> {
                                closeModal()
                                confirmAlert({
                                    customUI: ({ onClose }) => {
                                        return (
                                            <ReportAccept
                                                loadingAccept={loading}
                                                onClose={onClose}
                                                title={`${t("admin.areYouSure")}`}
                                                desc={`${t("admin.youAreGoingToDeleteThisUsers")}`}
                                                onSubmitAccept={()=>{
                                                    const id = userId;
                                                    deleteUsers(id)
                                                }}/>
                                        );
                                    }
                                });
                            }}
                        >
                            {loading ? `${t("admin.loading")} ...` : `${t("admin.delete")}`}
                        </Button>
                    )}
                </div>
            </DialogActions>
        </form>
    )
}

export default AdminUsersForm
