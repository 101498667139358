function parseErrorMessage(err){
    if(err.response) {
        if (err.response.data) {
            if (err.response.data.message) {
                return err.response.data.message
            } else {
                return err.response.data
            }
        } else {
            return err.response
        }
    } else {
        console.log("err " + err)
        return "Oups! Quelque chose ne va pas, réessayez plus tard."
    }
}

export  default parseErrorMessage;