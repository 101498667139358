import React from "react";

const PageTitle = ({ title, location, subLocation }) => {
  return (
      <div className="py-2 md:py-6 flex flex-col gap-1 w-fit flex-none">
        <h1 className="text-xl md:text-3xl font-bold text-gray-700">
          {title}
        </h1>
          {location && (
              <div className="flex text-sm md:text-md">
                  <span className="text-gray-500">{location}</span>
                  {subLocation && (
                      <span className="ml-1.5 text-[#A5A5A5] font-light">
                        {" "}
                          / {subLocation}
                      </span>
                  )}
              </div>
          )}
      </div>
  );
};

export default PageTitle;
