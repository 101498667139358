import requests from "./httpService";

const AdminServices = {
  loginAdmin: async (body) => {
    return requests.post(`/login`, body);
  },

  getAllAdmin: async (perPage, page, search) => {
    return requests.get(`/admins?perPage=${perPage ?? "100"}&page=${page ?? 1}&q=${search ?? ''}`);
  },

  CheckEmail: async (body) => {
    return requests.post("/check-email", body);
  },

  changePassword: async (body) => {
    return requests.put("/change-password", body);
  },

  choosePassword: async (body) => {
    return requests.put("/choose-password", body);
  },

  forgetPassword: async (body) => {
    return requests.put("/reset-password", body);
  },

  resetPassword: async (body) => {
    return requests.put("/admin/reset-password", body);
  },

  addAdminUsers: async (body) => {
    return requests.post(`/admins`, body);
  },

  logout: async () => {
    return requests.post("/logout");
  },
};

export default AdminServices;
