import requests from "./httpService";

const DealerServices = {
  loadAllDealers: async (perPage, page, search) => {
    return requests.get(`/dealers?perPage=${perPage ?? "100"}&page=${page ?? 1}&q=${search ?? ''}`);
  },

  loadAllDealerUsers: async (perPage, page, search) => {
    return requests.get(`/accounts?perPage=${perPage ?? "100"}&page=${page ?? 1}&q=${search ?? ''}`);
  },

  addDealerUsers: async (body) => {
    return requests.post(`/accounts`, body);
  },

  lockOrUnlockUsers: async (body) => {
    return requests.put(`/admins`, body);
  },

  deleteUsers: async (body) => {
    return requests.delete(`/admins`, body);
  },

  deleteDealerUsers: async (body) => {
    return requests.delete(`/accounts`, body);
  },

  addDealer: async (body) => {
    return requests.post(`/dealers`, body);
  },

  editDealer: async (body) => {
    return requests.put(`/dealers`, body);
  },

  lockDealer: async (body) => {
    return requests.put(`/dealers/lock`, body);
  },

  unlockDealer: async (body) => {
    return requests.put(`/dealers/unlock`, body);
  },

  loadLastDealer: async () => {
    return requests.get(`/dealers/latest`);
  }
};

export default DealerServices;
